import React, { useState, useEffect } from "react";

import AdEngageLayout from "components/layout";
import {  CURRENT_TOP_EVENT_KEY, TOP_EVENTS } from "utils/constants";

import classNames from "classnames";
import * as  styles from "components/category_overview/styles.module.scss";
import * as colors from "styles/colors.module.scss";
import AdEngageButton from "components/ad_engage_button";
import { Link } from "gatsby";
import MarchMadnessOverview from "components/category_overview/MarchMadnessOverview";
import TopAdsEmailForm from "components/top_ads_email_form";

declare global {
  interface Window {
    lintrk?: any;
    gtag?: any;
  }
}

const WomensMarchMadnessPublicAdEngage = () => {
  useEffect(() => {
    if (window.gtag) {
      window.gtag('event', 'conversion', {
        'allow_custom_scripts': true,
        'send_to': 'DC-12429278/visit/nflpa0+unique'
      });
    }
  });

  const pageHeader = () => (
    <div className={classNames(styles.pageHeader)}>
      <div className={classNames(styles.headerBanner)}>
        <div className={classNames(styles.headerTitle)}>
          <h2 className={classNames(colors.white, styles.headerBold, "edo-h2")}>Don't Miss a March Madness Update </h2>
        </div>
        <div className={styles.emailFormContainer}>
          <TopAdsEmailForm />
        </div>
      </div>
    </div>
  );

  const pageFooter = () => (
    <div className={classNames(styles.pageFooter)}>
      <div className={"flex-col"}>
        <div className={classNames(styles.footerText, styles.nflFooter)}>
          When your ad is on the line, access to real-time customer engagement is the only thing that counts. <b>Contact one of our industry specialists to learn more.</b>
        </div>
        <div className={styles.nflDemoButton}>
          <AdEngageButton label="Reach Out" gotoLink="/contact-us" href={"/contact-us"}
                          extraClassNames={classNames(styles.headerButton, styles.demoButton)}/>
        </div>
      </div>
    </div>
  );

  return (
    <AdEngageLayout pageTitle={TOP_EVENTS.womensMarchMadness.title}>
      <MarchMadnessOverview
        pageHeader={pageHeader()}
        pageFooter={pageFooter()}
        isWomens
      />
    </AdEngageLayout>
  )
};

export default WomensMarchMadnessPublicAdEngage;
