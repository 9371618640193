import React, { useState, useContext, useEffect, useRef, ReactNode } from "react";
import classNames from "classnames";
import { LoadingIndicatorInline } from "../loading_indicator/index";
import CategorySideBarMenu from "../category_sidebar_menu/index";
import SideBar, { IMenuItem } from "../sidebar/index";
import { CategoryMetadataFile } from "models/category";
import { adEngagePath } from "utils/constants";
import MoreBrandsMoreCategoriesModal from "../more_brands_more_categories";
import { scrollIntoView } from "seamless-scroll-polyfill";

import * as styles from "./styles.module.scss";

import {
  fetchCategoriesMetaData, fetchMarchMadnessData, fetchWomensMarchMadnessData, fetchWomensWorldCupData,
  fetchWorldCupData
} from "utils/ad_engage";

import { MixPanelContext } from "context/mixpanel_context";
import {
  TopEventCreativeMetadata,
  TopEventCreativeByCategoryMetadata
} from "models/top_event_creative";
import { NewTopEventCreativeCard } from "../creative_card/index";
import { CategoryOverviewPageHeader, getSidebarMenuItems } from "./CategoryOverview";
import {Link} from "gatsby";

export interface MarchMadnessOverviewProps {
  pageHeader?: ReactNode;
  pageFooter?: ReactNode;
  headerTitle?: string;
  isWomens?: boolean;
}

const marchMadnessOrder = [
  "Championship",
  "Final Four",
  "Elite Eight",
  "Sweet Sixteen",
  "Second Round",
  "First Round"
];

const MarchMadnessOverview: React.FC<(MarchMadnessOverviewProps)> = (props) => {
  const { pageHeader, pageFooter, isWomens } = props;
  const [topAdsByCategory, setTopAdsByCategory] = useState<{ [key: string]: TopEventCreativeByCategoryMetadata }>(null);
  const [contentLoading, setContentLoading] = useState<boolean>(true);
  const [sideBarLoading, setSideBarLoading] = useState(true);
  const [categoryMetadata, setCategoryMetadata] = useState<CategoryMetadataFile>();
  const [showModal, setShowModal] = useState(false);
  const mixpanel = useContext(MixPanelContext);

  useEffect(() => {
    Promise.all([
      fetchCategoriesMetaData()
      .then((data) => { setCategoryMetadata(data) })
      .catch((err) => { console.log(err) }),
      (isWomens ? fetchWomensMarchMadnessData() : fetchMarchMadnessData())
      .then((data) => { setTopAdsByCategory(data.data); })
      .catch((err) => { console.log(err) })
    ]).finally(() => { 
      setSideBarLoading(false);
      setContentLoading(false);
     });
  }, []);

  const contentRef = useRef<null | HTMLDivElement>(null);
  // useEffect(() => {
  //   contentRef?.current && scrollIntoView(contentRef.current, { block: "nearest", inline: "start"});
  // }, [sideBarLoading, contentLoading])

  const renderContent = () => {
    if (contentLoading) {
      return (
        <div className={classNames("flex-fill-remainder", styles.loadingIndicatorContainer)}>
          <LoadingIndicatorInline width="64px" height="64px" />
        </div>
      )
    }

    return (
      <div>
        <div className={classNames("flex-col", styles.nflWeekCol)}>
          <div className={styles.nflWeekText}>Sorted by Performance</div>
        </div>
        <div className={"flex-row flex-wrap"}>
          { topAdsByCategory && marchMadnessOrder.map((key) => (
            <>
              { topAdsByCategory[key] && topAdsByCategory[key]["top_ads"] && topAdsByCategory[key]["top_ads"].length > 0 && (
                <div key={`${key}-container`} className={"flex-row flex-wrap"} style={{ margin: "10px 0", width: "100%" }}>
                  <div key={`${key}-category-name`} style={{ fontSize: 20, fontWeight: 600, marginBottom: 5 }}>
                    {topAdsByCategory[key]["name"]} <span style={{paddingLeft: "4px", opacity: 0.5, fontWeight: 'normal' }}>Top Performing Ads</span>
                  </div>
                  <div key={`${key}-category-see-more`} className={styles.seeMore}>
                    <Link to={"/contact-us"} >{"see more..."}
                    </Link>
                  </div>
                  <div key={`${key}-top-ads-container`} className={classNames("flex-row", styles.topAdsRow)}>
                    {
                      topAdsByCategory[key] && topAdsByCategory[key]["top_ads"].slice(0,10).map((entity: TopEventCreativeMetadata, i: number) => {
                        return <NewTopEventCreativeCard
                          creative_id={entity.creative_id}
                          brand_name={entity.brand_name}
                          creative_title={entity.creative_title}
                          category_name={entity.category_name}
                          tv_ad_airings_count={entity.airing_count}
                          thumbnail_url={entity.thumbnail_url}
                          brand_image_url={entity.brand_image_url}
                          duration={entity.duration}
                          key={`${i}-creative-card`}
                          rank={i + 1}
                        />;
                      })
                    }
                  </div>
                </div>
              )}
            </>

          )) }
        </div>
      </div >
    )
  }

  return (
    <>
      <MoreBrandsMoreCategoriesModal open={showModal} onClose={() => setShowModal(false)} />
      { pageHeader || <CategoryOverviewPageHeader /> }
      <div className={classNames("fill-parent", styles.categoryContent)}>
      <div className={classNames(styles.worldCupHeaderContainer, styles.categoryOverviewHeader)}>

        <div className={classNames("flex-col")}>
          <div className={styles.categoryOverviewDisclaimerText}>
            <span className={styles.categoryOverviewDisclaimerBoldText}>Discover the most effective creatives of the { isWomens ? "the 2024 NCAA Women's Basketball Tournament" : "the 2024 NCAA Men's Basketball Tournament" }</span>!
          </div >
          <div className={styles.categoryOverviewDisclaimerText}>
            Only EDO reports the real-time TV performance of every brand advertising during <span>{ isWomens ? "Women's" : "Men's" }</span> March Madness, as ranked by which ads drove the most consumer engagement — from the first game whistle to the Championship.
          </div >
          <div className={styles.categoryOverviewDisclaimerText}>
            Want a deeper dive on your brand’s NCAA performance? Connect with us at <a href={"https://www.edo.com/contact-us"}>edo.com/contact-us</a>.
          </div>
        </div>
      </div>
      <div className={"flex-row"}>
        <SideBar>
          {sideBarLoading
            ? <div className={classNames("flex-col", styles.loadingIndicatorContainer)}>
              <LoadingIndicatorInline width="64px" height="64px" />
            </div>
            : <CategorySideBarMenu baseUrl={adEngagePath} menuItems={getSidebarMenuItems(categoryMetadata, setShowModal, mixpanel)} currentPath={isWomens ? "ad-engage/womens-march-madness" : "ad-engage/march-madness"} />
          }
        </SideBar>
        <div ref={contentRef} className={classNames("flex-fill-remainder full-height-and-width-minus-navbar-and-sidebar", styles.categoryOverview)}>
          {renderContent()}
        </div>
      </div>
      </div>
      { pageFooter }
    </>
  );
};

export default MarchMadnessOverview;
